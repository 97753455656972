export const ROUTES_TO_IGNORE = [];

// Features
export const LOGIN = "Login";
export const SIGN_OUT = "SignOut";

// View types
export const PAGE_SUFFIX = "Page";
export const MODAL_SUFFIX = "Modal";

// Pages
export const LOGIN_PAGE = `${LOGIN}${PAGE_SUFFIX}`;

// UI element types
export const COMPARER = "Comparer";
export const FILTER = "Filter";
export const SORTER = "Sorter";
export const BUTTON = "Button";
export const ACCORDION = "Accordion";
