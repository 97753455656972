import { Grid } from "@mui/material";
import React from "react";
import Checklist from "../../components/Checklist/Checklist";
import Stuff from "../../components/Stuff/Stuff";
import DoOneThing from "../../components/DoOneThing/DoOneThing";
import ChannelGroov from "../../components/ChannelGroov/ChannelGroov";
import { selectIsUserAuthenticated } from "../../store/selectors/authSelectors";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { loginAction } from "../../store/actions/authActions";
import { LoadingCard } from "@mentemia/library.web";

const Home = () => {
  const dispatch = useAppDispatch();
  const showCallToAction = false;
  const showOnboardingChecklist = false;
  const isLoggedIn = useAppSelector(selectIsUserAuthenticated);

  if (!isLoggedIn) {
    dispatch(loginAction());
    return <LoadingCard />;
  }

  return (
    <Grid container spacing={4}>
      {showCallToAction && (
        <Grid item xs={12}>
          <DoOneThing />
        </Grid>
      )}
      {showOnboardingChecklist && (
        <Grid item xs={12} md={4}>
          <Checklist />
        </Grid>
      )}
      <Grid item xs>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ChannelGroov />
          </Grid>
          <Grid item xs={12}>
            <Stuff />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
