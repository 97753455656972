import React from "react";

interface Props {
  "data-testid": string;
}

const Locked: React.FC<Props> = ({ "data-testid": dataTestId }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestId}
    >
      <path
        d="M20 10H4C3.44772 10 3 10.4477 3 11V21C3 21.5523 3.44772 22 4 22H20C20.5523 22 21 21.5523 21 21V11C21 10.4477 20.5523 10 20 10Z"
        stroke="#1C1C1C"
        strokeWidth="2"
      />
      <path
        d="M12 14V18"
        stroke="#1C1C1C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C13.1046 17 14 16.1046 14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 16.1046 10.8954 17 12 17Z"
        fill="#1C1C1C"
      />
      <path
        d="M17 10V7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7V10"
        stroke="#1C1C1C"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Locked;
