import { Grid, Typography } from "@mui/material";
import React from "react";
import { LOCKED_CONTENT_TEXT } from "./Stuff.constants";
import {
  STUFF_TILE_TITLE_HOVERED_ID,
  STUFF_TILE_DESCRIPTION_ID,
  STUFF_TILE_LOCKED_NOTE_ID,
  STUFF_TILE_LINK_ID,
} from "./Stuff.ids";
import StuffTitleSection from "./StuffTitleSection";
import { TileDetails } from "./TileDetails";
import colors from "../../styles/colors";
import { GridContainer, Link } from "@mentemia/library.web";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectIsUserAuthenticated } from "../../store/selectors/authSelectors";
import { ENCRYPTION_KEY } from "../../config/app";
import { constructRedirectUrl } from "../../utils/urlHelpers";

const HoveredTile = ({ details }: { details: TileDetails }) => {
  const accessToken = useAppSelector(selectIsUserAuthenticated);
  const url = constructRedirectUrl(details.url, ENCRYPTION_KEY, accessToken);

  return (
    <>
      <GridContainer align="top" spacing={1}>
        <Grid item>
          <StuffTitleSection
            details={details}
            data-testid={STUFF_TILE_TITLE_HOVERED_ID}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            color={colors.neutral[100]}
            data-testid={STUFF_TILE_DESCRIPTION_ID}
          >
            {details.description}
          </Typography>
        </Grid>
      </GridContainer>
      <GridContainer align="bottom" spacing={1}>
        <Grid item>
          {details.locked ? (
            <Typography
              variant="subtitle1"
              color={colors.blue[130]}
              data-testid={STUFF_TILE_LOCKED_NOTE_ID}
            >
              {LOCKED_CONTENT_TEXT}
            </Typography>
          ) : (
            <Link
              variant="text"
              label={details.label}
              link={url}
              data-testid={STUFF_TILE_LINK_ID}
            />
          )}
        </Grid>
      </GridContainer>
    </>
  );
};

export default HoveredTile;
