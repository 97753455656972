import { encryptString } from "@groov/ui";

export const constructRedirectUrl = (
  url: string,
  encryptionKey?: string,
  accessToken?: string
): string => {
  if (accessToken && encryptionKey) {
    const encryptedAccessToken = encryptString(encryptionKey, accessToken);
    const encodedAccessToken = encodeURIComponent(encryptedAccessToken);
    return `${url}?key=${encodedAccessToken}`;
  }

  return url;
};
