import { createTheme } from "@mui/material/styles";
import colors from "./colors";

const fontFamily = ["Averta", "Helvetica", "Verdana", "sans-serif"].join(",");

const theme = createTheme({
  palette: {
    background: {
      default: colors.blue[0],
    },
  },
  components: {
    MuiGrid: {
      defaultProps: {
        // border: `5px solid black`, //todo remove, for debugging only
      },
      styleOverrides: {
        root: {
          // backgroundColor: groovColors.blue[10], //todo remove, for debugging only
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  typography: {
    h1: {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: 32,
      lineHeight: "40px",
    },
    h2: {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: 24,
      lineHeight: "32px",
    },
    h3: {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: 20,
      lineHeight: "30px",
    },
    h5: {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: 12,
      lineHeight: "14.83px",
    },
    h6: {
      //`meta` in the designs
      fontFamily: fontFamily,
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: 14,
      lineHeight: "20px",
    },
    body1: {
      //`paragraph` in the designs
      fontFamily: fontFamily,
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: "24px",
    },
    subtitle1: {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: "24px",
    },
    button: {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: "24px",
      textTransform: "none",
    },
  },
});

export default theme;
