const colors = {
  neutral: {
    0: "#FFFFFF",
    10: "#F2F5F9",
    20: "#E6EBF3",
    30: "#C6CBD5",
    40: "#A5ABB7",
    60: "#565E74",
    70: "#4D5468",
    80: "#434A5B",
    95: "#1C1C1C",
    100: "#000000",
  },
  blue: {
    0: "#F8FCFF",
    1: "#EDF7FF",
    5: "#CCE9FF",
    30: "#88C2FF",
    80: "#4A9EFF",
    100: "#006AFF",
    110: "#0051DB",
    130: "#002A93",
  },
  mint: {
    5: "#E4FDED",
    10: "#CAFCE0",
    15: "#B7FFEC",
    80: "#9DF0D0",
    90: "#85E9CA",
    100: "#74E4C6",
    130: "#258284",
  },
  purple: {
    5: "#F2EEFF",
    20: "#C9BBF7",
  },
  mauve: {
    5: "#F7ECF3",
    130: "#442251",
  },
  orange: {
    5: "#FDEED9",
    130: "#681B10",
  },
  red: {
    5: "#FDE5D8",
  },
};

export default colors;
