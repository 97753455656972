import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectAuthState = (state: RootState) => state.auth;

export const selectIsUserAuthenticated = createSelector(
  selectAuthState,
  (auth) => auth.accessToken
);

export const selectOrgId = createSelector(
  selectAuthState,
  (auth) => auth.orgId
);
