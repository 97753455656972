import { Grid, Typography } from "@mui/material";
import React from "react";
import { GROOVY_STUFF } from "../../pages/Home/Home.constants";
import colors from "../../styles/colors";
import StuffTile from "./StuffTile";
import { STUFF_TILE_ID } from "./Stuff.ids";
import { useGetResourcesQuery } from "../../store/services/myGroovService";
import { ErrorCard, LoadingCard } from "@mentemia/library.web";
import { IS_DEVELOPMENT } from "../../config/app";

const Stuff = () => {
  const { data, error, isLoading } = useGetResourcesQuery();

  if (error) {
    return <ErrorCard error={error} isDevelopment={IS_DEVELOPMENT} />;
  } else if (isLoading || !data) {
    return <LoadingCard />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" color={colors.blue[130]}>
          {GROOVY_STUFF}
        </Typography>
      </Grid>
      {data.resources
        .slice()
        .sort((a, b) => a.rank - b.rank)
        .map((a) => {
          return (
            <StuffTile details={a} key={a.title} data-testid={STUFF_TILE_ID} />
          );
        })}
    </Grid>
  );
};

export default Stuff;
