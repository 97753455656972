import { User, UserManager, UserManagerSettings } from "oidc-client-ts";
import { GroovUser } from "./GroovUser";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? "";
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET ?? "";
const AUTHORITY_URL = process.env.REACT_APP_IDENTITY_SERVICE_URL ?? "";
const APP_URL = process.env.REACT_APP_URL ?? "http://localhost:3000";

const config: UserManagerSettings = {
  authority: AUTHORITY_URL,
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
  redirect_uri: `${APP_URL}/logincallback`,
  post_logout_redirect_uri: `${APP_URL}/logoutcallback`,
  scope:
    "openid profile orgId status offline_access mygroov sixpillarsreport content",
};

const userManager = new UserManager(config);

export const signIn = async () => {
  await userManager.signinRedirect();
};

export const completeSignIn = async (): Promise<User> => {
  const user = await userManager.signinRedirectCallback();
  return user;
};

export const signOut = async () => {
  await userManager.signoutRedirect();
};

export const completeSignOut = async (): Promise<void> => {
  await userManager.signoutRedirectCallback();
  await userManager.removeUser();
};

export const getUser = async () => {
  return await userManager.getUser();
};

export const getUserFromSessionStorage = (): GroovUser => {
  const session = sessionStorage.getItem(
    `oidc.user:${AUTHORITY_URL}:${CLIENT_ID}`
  );

  const oidcUser: GroovUser = session ? JSON.parse(session) : null;
  return oidcUser;
};
