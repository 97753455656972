import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { completeLoginAction } from "../../store/actions/authActions";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectOrgId } from "../../store/selectors/authSelectors";

const LoginCallback = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orgId = useAppSelector(selectOrgId);

  useEffect(() => {
    dispatch(completeLoginAction());
  }, []);

  useEffect(() => {
    if (orgId !== "") navigate("/");
  }, [orgId]);

  return <div></div>;
};

export default LoginCallback;
