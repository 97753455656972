import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { MY_GROOV_API } from "../../config/api";
import { TileDetails } from "../../components/Stuff/TileDetails";
import { addAuthorizationHeader } from "@mentemia/library.web";
import { FeaturedContent } from "../../components/ChannelGroov/FeaturedContent";

const baseQuery = fetchBaseQuery({
  baseUrl: MY_GROOV_API.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.accessToken;
    addAuthorizationHeader(headers, accessToken);
  },
});

export const myGroovApi = createApi({
  reducerPath: "myGroov",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getResources: builder.query<{ resources: TileDetails[] }, void>({
      query: () => `${MY_GROOV_API.ENDPOINTS.RESOURCES}`,
    }),
    getFeaturedContent: builder.query<FeaturedContent, void>({
      query: () => `${MY_GROOV_API.ENDPOINTS.FEATURED_CONTENT}`,
    }),
    getUserInfo: builder.query<{ orgId: string; orgName: string }, void>({
      query: () => `${MY_GROOV_API.ENDPOINTS.USER}`,
    }),
  }),
});

export const {
  useGetResourcesQuery,
  useGetFeaturedContentQuery,
  useGetUserInfoQuery,
} = myGroovApi;
