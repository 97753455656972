import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  completeSignIn,
  completeSignOut,
  signIn,
  signOut,
} from "../../auth/AuthService";
import { User } from "oidc-client-ts";

export const loginAction = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("auth/login", async (_, { rejectWithValue }) => {
  try {
    await signIn();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const completeLoginAction = createAsyncThunk<
  User,
  void,
  {
    state: RootState;
  }
>("auth/completeLogin", async (_, { rejectWithValue }) => {
  try {
    const user = await completeSignIn();
    return user;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const logoutAction = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("auth/logout", async (_, { rejectWithValue }) => {
  try {
    await signOut();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const completeLogoutAction = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("auth/completeLogout", async (_, { rejectWithValue }) => {
  try {
    await completeSignOut();
  } catch (error) {
    return rejectWithValue(error);
  }
});
