import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { completeLogoutAction } from "../../store/actions/authActions";

const LogoutCallback = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(completeLogoutAction());
    navigate("/");
  }, []);

  return <div></div>;
};

export default LogoutCallback;
