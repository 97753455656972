import { Grid } from "@mui/material";
import React from "react";
import { STUFF_TILE_ICON_ID, STUFF_TILE_TITLE_ID } from "./Stuff.ids";
import StuffTitleSection from "./StuffTitleSection";
import { TileDetails } from "./TileDetails";
import { GridContainer } from "@mentemia/library.web";

const DefaultTile = ({ details }: { details: TileDetails }) => {
  return (
    <>
      <GridContainer align="top" spacing={1}>
        <Grid item>
          <Grid container>
            <Grid item xs data-testid={STUFF_TILE_ICON_ID}>
              <div dangerouslySetInnerHTML={{ __html: details.image }} />
            </Grid>
          </Grid>
        </Grid>
      </GridContainer>
      <GridContainer align="bottom" spacing={1}>
        <Grid item>
          <StuffTitleSection
            details={details}
            data-testid={STUFF_TILE_TITLE_ID}
          />
        </Grid>
      </GridContainer>
    </>
  );
};

export default DefaultTile;
