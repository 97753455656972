import { createSlice } from "@reduxjs/toolkit";
import jwt_decode, { JwtPayload } from "jwt-decode";
import {
  completeLoginAction,
  completeLogoutAction,
} from "../actions/authActions";
import { getUserFromSessionStorage } from "../../auth/AuthService";

const oidcUser = getUserFromSessionStorage();
const accessToken = oidcUser?.access_token ?? "";
const refreshToken = oidcUser?.refresh_token ?? "";
const orgId = oidcUser?.profile.org_id ?? "";
const userId = oidcUser?.profile.sub ?? "";
const emailAddress = oidcUser?.profile.preferred_username ?? "";

interface AuthState {
  loading: boolean;
  accessToken: string;
  userId: string;
  orgId: string;
  expiry: number;
  refreshToken: string;
  emailAddress: string;
  error: unknown;
}

const initialState: AuthState = {
  loading: false,
  accessToken,
  userId,
  orgId,
  expiry: 0,
  refreshToken,
  emailAddress,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(completeLoginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeLoginAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.accessToken = payload.access_token;
        const { org_id, sub } = parseAccessToken(payload.access_token);
        state.userId = sub;
        state.orgId = org_id;
        state.emailAddress = payload.profile.preferred_username ?? "";
        state.expiry = payload.expires_at ?? 0;
        state.refreshToken = payload.refresh_token ?? "";
      })
      .addCase(completeLoginAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(completeLogoutAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeLogoutAction.fulfilled, () => {
        return initialState;
      })
      .addCase(completeLogoutAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

interface AccessToken extends JwtPayload {
  sub: string;
  org_id: string;
  preferred_username: string;
}

const parseAccessToken = (token: string): AccessToken => {
  return jwt_decode<AccessToken>(token);
};

export const authReducer = authSlice.reducer;

export default authSlice;
