import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import colors from "../../styles/colors";
import { CHANNEL_GROOV } from "./ChannelGroov.constants";
import theme from "../../styles/theme";
import ChannelGroovButton from "./ChannelGroovButton";
import { CHANNEL_GROOV_PANEL_ID } from "./ChannelGroov.ids";
import {
  ErrorCard,
  GridContainer,
  GridItemFading,
  Link,
  LoadingCard,
  Paper,
  useAppMediaQuery,
} from "@mentemia/library.web";
import { useGetFeaturedContentQuery } from "../../store/services/myGroovService";
import { IS_DEVELOPMENT } from "../../config/app";

const ChannelGroov = () => {
  const { xsScreenAndSmScreen } = useAppMediaQuery();
  const { data, isLoading, error } = useGetFeaturedContentQuery();
  const titleMaxChar = 100;

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (error) {
    return <ErrorCard error={error} isDevelopment={IS_DEVELOPMENT} />;
  } else if (isLoading || !data) {
    return <LoadingCard />;
  }

  return (
    <Grid container spacing={3} data-testid={CHANNEL_GROOV_PANEL_ID}>
      <Grid item xs={12}>
        <Typography variant="h2" color={colors.blue[130]}>
          {CHANNEL_GROOV}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          backgroundColor={colors.blue[5]}
          borderRadius={theme.spacing(4)}
          padding={`${theme.spacing(4)} ${theme.spacing(2.5)}`}
        >
          <Grid container spacing={theme.spacing(xsScreenAndSmScreen ? 2 : 4)}>
            <Grid item xs={12} md display={"flex"} alignItems={"center"}>
              <GridContainer align="top" spacing={2.25}>
                <Grid item>
                  <Paper
                    backgroundColor={colors.mint[90]}
                    borderRadius={theme.spacing(3)}
                    padding={`${theme.spacing(1)} ${theme.spacing(1.5)}`}
                    maxWidth="fit-content"
                  >
                    <Typography variant="subtitle1" color={colors.blue[110]}>
                      {data.date}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item>
                  <Typography variant="h2" color={colors.blue[130]}>
                    {data.title.length > titleMaxChar
                      ? `${data.title.slice(0, titleMaxChar).trim()}...`
                      : data.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link variant="wrapper" link={data.url}>
                    <GridItemFading
                      isHovered={isHovered}
                      handleMouseOver={handleMouseOver}
                      handleMouseLeave={handleMouseLeave}
                      defaultState={<ChannelGroovButton isHovered={false} />}
                      hoveredState={<ChannelGroovButton isHovered={true} />}
                      containerHeight={52}
                      maxWidth="fit-content"
                    />
                  </Link>
                </Grid>
              </GridContainer>
            </Grid>
            <Grid item xs={12} md>
              <div dangerouslySetInnerHTML={{ __html: data.html }} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChannelGroov;
