import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import theme from "../styles/theme";
import { trackEvent } from "../analytics/AnalyticsPublisher";
import { QuickNav } from "@groov/ui";
import "../styles/quickNav.css";
import { AnalyticsEvent } from "../analytics/AnalyticsEvent";
import { useAppSelector } from "../hooks/useAppSelector";
import {
  selectIsUserAuthenticated,
  selectOrgId,
} from "../store/selectors/authSelectors";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { loginAction, logoutAction } from "../store/actions/authActions";
import {
  Cookie,
  CookiePopup,
  Header,
  useAppMediaQuery,
} from "@mentemia/library.web";
import { useGetUserInfoQuery } from "../store/services/myGroovService";

interface Props {
  children?: React.ReactNode;
}

//leaving the cookie-responsibility to the consuming project
const defaultCookies: Cookie[] = [
  {
    name: "Cookies and cream",
    enabled: true,
  },
  {
    name: "Cookie dough",
    enabled: true,
  },
];

const Layout = (props: Props) => {
  const showCookiePopup = false;
  const { mdScreen, xsScreenAndSmScreen } = useAppMediaQuery();
  // console.log("🚀 ", currentBreakpoint(), " ", columns); //todo remove, for debugging only
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectIsUserAuthenticated);
  const { data } = useGetUserInfoQuery();
  const authOrgId = useAppSelector(selectOrgId);
  const orgId = data?.orgId ?? authOrgId;

  const onAuthButtonClick = async () => {
    if (accessToken) {
      trackEvent(AnalyticsEvent.OnPressedEvent, {
        elementType: "Button",
        elementName: "QuickNavLogout",
      });

      dispatch(logoutAction());
    } else {
      trackEvent(AnalyticsEvent.OnPressedEvent, {
        elementType: "Button",
        elementName: "QuickNavSignIn",
      });

      dispatch(loginAction());
    }
  };

  const onMenuClick = () => {
    trackEvent(AnalyticsEvent.LinkPressedEvent, {
      elementType: "Link",
      elementName: "QuickNavMenuItem",
    });
  };

  const [customise, setCustomise] = useState(false);
  const [cookies, setCookies] = useState<Cookie[]>(defaultCookies);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} padding={theme.spacing(4)}>
        <Header
          organisationName={data?.orgName ?? ""}
          handleAnalytics={trackEvent}
          quickNav={
            <QuickNav
              accessToken={accessToken}
              organisationId={orgId}
              encryptionKey={
                process.env.REACT_APP_TOKEN_EXCHANGE_ENCRYPTION_KEY
              }
              onSignInClick={onAuthButtonClick}
              onSignOutClick={onAuthButtonClick}
              onMenuClick={onMenuClick}
            />
          }
        />
      </Grid>
      <Grid
        item
        sm={12}
        md={10}
        margin={
          mdScreen
            ? `0 ${theme.spacing(4)}`
            : xsScreenAndSmScreen
            ? `0 ${theme.spacing(2)}`
            : `0 0`
        }
        sx={{
          "@media (min-width:1200px)": {
            // lg-xl
            maxWidth: "1120px",
            // backgroundColor: "pink", //todo remove, for debugging only
          },
          "@media (max-width: 1199px) and (min-width: 900px)": {
            // md
            maxWidth: "736px", //as per designs this is smaller than sm screen
            // backgroundColor: "#ffffb2", //todo remove, for debugging only
          },
          "@media (max-width: 899px) and (min-width: 600px)": {
            // sm
            minWidth: "0px",
            maxWidth: "835px",
            // backgroundColor: "grey", //todo remove, for debugging only
          },
          "@media (max-width: 599px)": {
            // xs
            minWidth: "0px",
            maxWidth: "568px",
            // backgroundColor: "#c8f7c8", //todo remove, for debugging only
          },
        }}
      >
        <main>{props.children}</main>
        <Box padding={theme.spacing(4)} />

        {showCookiePopup && (
          <CookiePopup
            cookies={cookies}
            setCookies={setCookies}
            customise={customise}
            setCustomise={setCustomise}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Layout;
