import { Grid } from "@mui/material";
import React, { useState } from "react";
import colors from "../../styles/colors";
import theme from "../../styles/theme";
import { TileDetails } from "./TileDetails";
import DefaultTile from "./DefaultTile";
import HoveredTile from "./HoveredTile";
import { GridItemFading, Paper, useAppMediaQuery } from "@mentemia/library.web";

interface Props {
  details: TileDetails;
  "data-testid"?: string;
}

const StuffTile: React.FC<Props> = ({ details, "data-testid": dataTestId }) => {
  const { xsScreenAndSmScreen } = useAppMediaQuery();

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid item xs={xsScreenAndSmScreen ? 12 : 6} data-testid={dataTestId}>
      <Paper
        backgroundColor={colors.neutral[20]}
        borderRadius={theme.spacing(1.5)}
        padding={theme.spacing(2.5)}
      >
        <GridItemFading
          isHovered={isHovered}
          handleMouseOver={handleMouseOver}
          handleMouseLeave={handleMouseLeave}
          defaultState={<DefaultTile details={details} />}
          hoveredState={<HoveredTile details={details} />}
          containerHeight={140}
        />
      </Paper>
    </Grid>
  );
};

export default StuffTile;
