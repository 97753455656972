const GROOV_API_BASE_URL = process.env.REACT_APP_GROOV_API_BASE_URL;

export const MY_GROOV_API = {
  BASE_URL: `${GROOV_API_BASE_URL}/mygroov`,
  ENDPOINTS: {
    RESOURCES: "/resources",
    FEATURED_CONTENT: "/featured-content",
    USER: "/user",
  },
};
