import React from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import Home from "./pages/Home/Home";
import { Provider } from "react-redux";
import { store } from "./store";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Main from "./Main";
import { CssBaseline } from "@mui/material";
import LoginCallback from "./pages/Auth/LoginCallback";
import LogoutCallback from "./pages/Auth/LogoutCallback";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Main />}>
        <Route path={"/"} element={<Home />} />
        <Route path={"/logincallback"} element={<LoginCallback />} />
        <Route path={"/logoutcallback"} element={<LogoutCallback />} />
      </Route>
    </>
  )
);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
