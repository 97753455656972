import { Grid, Typography } from "@mui/material";
import React from "react";
import Locked from "../../assets/Locked";
import { STUFF_TILE_LOCK_ID } from "./Stuff.ids";
import { TileDetails } from "./TileDetails";
import colors from "../../styles/colors";

interface Props {
  details: TileDetails;
  "data-testid"?: string;
}

const StuffTitleSection: React.FC<Props> = ({
  details,
  "data-testid": dataTestId,
}) => {
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={"auto"}>
        <Typography
          variant="h2"
          color={colors.neutral[100]}
          data-testid={dataTestId}
        >
          {details.title}
        </Typography>
      </Grid>
      <Grid item xs={"auto"} display={"flex"} alignItems={"center"}>
        {details.locked && <Locked data-testid={STUFF_TILE_LOCK_ID} />}
      </Grid>
    </Grid>
  );
};

export default StuffTitleSection;
